<template>
  <v-container fluid>
    <v-card-title>
      질문
      <v-spacer />
      <v-btn color="deep-purple accent-2" dark @click="getListQuestion">
        새로고침
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>

    <v-card outlined class="mb-3">
      <v-row class="ml-3 pa-3">
        <v-checkbox v-model="req.checked" label="대기중인 답변만 보기" color="#ffd600" />
      </v-row>
    </v-card>

    <v-card outlined>
      <v-data-table
        :headers="headers"
        :items="questions"
        :items-per-page="req.offset"
        sort-by="id"
        sort-desc
        hide-default-footer
        :item-class="(item) => (item.reply ? 'active' : '')"
        @click:row="(item) => $router.push(`question/${item.id}`)"
      >
        <template v-slot:item.stamp="{ item }">
          <QuestionTag :tags="item.stamp" />
        </template>
        <template v-slot:item.content="{ item }">
          {{ preview(item.content, 180) }}
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ day(item.createdAt) }}
        </template>
        <template v-slot:item.reply.author.name="{ item }">
          {{ item.reply ? item.reply.author.name : null }}
        </template>
        <template v-slot:item.status="{ item }">
          <StatusTag
            :tag="{
              show: item.status,
              reply: item.reply ? true : false,
            }"
          />
        </template>
        <template v-slot:item.questioner.name="{ item }">
          {{ item.questioner ? `${item.questioner.name}(${item.questioner.id})` : null }}
        </template>

        <template v-slot:footer>
          <v-divider />
          <v-container fluid class="row" no-gutters>
            <v-col cols="1">
              <v-select v-model="req.offset" :items="[5, 10, 15, 25, 50, 100]" outlined dense hide-details />
            </v-col>
            <v-spacer />
            <v-col>
              <v-pagination v-model="req.page" total-visible="13" :length="pageCount" circle color="#ffd600" @input="getListQuestion" />
            </v-col>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs, watch } from '@vue/composition-api'
import { preview, day } from '@/helper'
import QuestionTag from '@/components/QuestionTag.vue'
import StatusTag from '@/components/StatusTag.vue'

export default defineComponent({
  components: { QuestionTag, StatusTag },

  setup(props, { root }) {
    const state = reactive({
      headers: [
        { text: 'ID', value: 'id', width: '5%', align: 'center' },
        {
          text: '라벨',
          value: 'stamp',
          width: '5%',
          align: 'start',
          class: 'text-center',
        },
        {
          text: '이름',
          value: 'questioner.name',
          width: '10%',
          align: 'start',
          class: 'text-center',
        },
        {
          text: '상태',
          value: 'status',
          width: '10%',
          align: 'start',
          class: 'text-center',
        },
        { text: '질문', value: 'content', sortable: false, width: '50%', align: 'start' },
        {
          text: '답변자',
          value: 'reply.author.name',
          width: '10%',
          align: 'start',
          class: 'text-center',
        },
        {
          text: '작성일시',
          value: 'createdAt',
          width: '10%',
          align: 'start',
          class: 'text-center',
        },
      ],
      questions: [],
      pageCount: null,
      req: {
        checked: sessionStorage.getItem('QuestionList/checked') === 'true',
        offset: sessionStorage.getItem('QuestionList/offset') ? Number(sessionStorage.getItem('QuestionList/offset')) : 15,
        page: sessionStorage.getItem('QuestionList/page') ? Number(sessionStorage.getItem('QuestionList/page')) : 1,
      },
    })

    const getListQuestion = async () => {
      try {
        sessionStorage.setItem('QuestionList/page', state.req.page)
        sessionStorage.setItem('QuestionList/offset', state.req.offset)
        sessionStorage.setItem('QuestionList/checked', state.req.checked)

        const listQuestion = await root.$store.dispatch('question/getListQuestion', {
          questionStatus: state.req.checked ? 'pending' : undefined,
          offset: state.req.offset,
          pageNumber: state.req.page,
          sort: {
            what: 'createdAt',
            sort: 'desc',
          },
        })

        state.pageCount = Math.ceil(listQuestion.totalCount / state.req.offset)
        state.questions = listQuestion.data
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    watch(
      () => state.req.checked,
      () => {
        state.req.page = 1
        getListQuestion()
      },
    )

    watch(
      () => state.req.offset,
      () => {
        state.req.page = 1
        getListQuestion()
      },
    )

    onBeforeMount(() => {
      getListQuestion()
    })

    return {
      ...toRefs(state),
      getListQuestion,
      preview,
      day,
    }
  },
})
</script>
