var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_vm._v(" 질문 "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"deep-purple accent-2","dark":""},on:{"click":_vm.getListQuestion}},[_vm._v(" 새로고침 "),_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card',{staticClass:"mb-3",attrs:{"outlined":""}},[_c('v-row',{staticClass:"ml-3 pa-3"},[_c('v-checkbox',{attrs:{"label":"대기중인 답변만 보기","color":"#ffd600"},model:{value:(_vm.req.checked),callback:function ($$v) {_vm.$set(_vm.req, "checked", $$v)},expression:"req.checked"}})],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.questions,"items-per-page":_vm.req.offset,"sort-by":"id","sort-desc":"","hide-default-footer":"","item-class":function (item) { return (item.reply ? 'active' : ''); }},on:{"click:row":function (item) { return _vm.$router.push(("question/" + (item.id))); }},scopedSlots:_vm._u([{key:"item.stamp",fn:function(ref){
var item = ref.item;
return [_c('QuestionTag',{attrs:{"tags":item.stamp}})]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.preview(item.content, 180))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.day(item.createdAt))+" ")]}},{key:"item.reply.author.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reply ? item.reply.author.name : null)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('StatusTag',{attrs:{"tag":{
            show: item.status,
            reply: item.reply ? true : false,
          }}})]}},{key:"item.questioner.name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.questioner ? ((item.questioner.name) + "(" + (item.questioner.id) + ")") : null)+" ")]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-container',{staticClass:"row",attrs:{"fluid":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-select',{attrs:{"items":[5, 10, 15, 25, 50, 100],"outlined":"","dense":"","hide-details":""},model:{value:(_vm.req.offset),callback:function ($$v) {_vm.$set(_vm.req, "offset", $$v)},expression:"req.offset"}})],1),_c('v-spacer'),_c('v-col',[_c('v-pagination',{attrs:{"total-visible":"13","length":_vm.pageCount,"circle":"","color":"#ffd600"},on:{"input":_vm.getListQuestion},model:{value:(_vm.req.page),callback:function ($$v) {_vm.$set(_vm.req, "page", $$v)},expression:"req.page"}})],1)],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }